import logo from "./logo.svg";
import "./App.css";
import "./../node_modules/swiper/swiper-bundle.min.css";
import "./../node_modules/swiper/swiper.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Study from "./Components/Pages/Abroad/Study";
import MainPage from "./Components/Pages/MainPage";
import Navbar from "./Components/Pages/Navbar";
import Footer from "./Components/Pages/Footer";
import Blog from "./Components/Pages/Blog/Blog";
import Floating from "./Components/Pages/floatingMenu/Floating";
import BlogDetails from "./Components/Pages/Blog/BlogDetails";
import ScrollAnime from "./Components/Pages/Animation/ScrollAnimation/ScrollAnime";
import About from "./Components/Pages/About/About";
import Chat from "./Components/Pages/ChatBot/Chat.js";
import Contact from "./Components/Pages/Contact/Contact.js";
import { Toaster } from "react-hot-toast";
import FindCourse from "./Components/Pages/Course/FindCourse.js";
import Apply from "./Components/Pages/Apply/Apply.js";

function App() {
  return (
    <BrowserRouter>
      <Floating />
      <Chat />
      <ScrollAnime />
      <Navbar />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/destination/:id" element={<Study />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/course" element={<FindCourse />} /> */}
        <Route path="/blog/:id" element={<BlogDetails />} />
        <Route path="/apply" element={<Apply/>} />
      </Routes>
      <Footer />
      <Toaster
        position="top-centered"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </BrowserRouter>
  );
}

export default App;
