import React from 'react'

function StudyOption({actions}) {

    const handleMessage = (country)=>{
        // actions.handleClientMessage(country)
        actions.handleStudyOption(country);
    }
    const options = [
        { text: "UK", handler: () => handleMessage("UK"), id: 1 },
        { text: "USA", handler: () => handleMessage("USA"), id: 2 },
        { text: "Australia", handler: () => handleMessage("Australia"), id: 3 },
        { text: "Canada", handler: () => handleMessage("Canada"), id: 4 },
        { text: "Germany", handler: () => handleMessage("Germany"), id: 5 },
        { text: "Ireland", handler: () => handleMessage("Ireland"), id: 6 },
        { text: "France", handler: () => handleMessage("France"), id: 7 },
    ];

      
  return (
    <div className="options-container">
        <div className='sec-msg'>
        Choose your preferred country to study?
        </div>
    {options.map((option) => (
     <span>
         <button key={option.id} onClick={option.handler} className="option-button">
        {option.text}
      </button>
     </span>
    ))}
  </div>
  )
}

export default StudyOption