import React, { useEffect, useState } from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import sendEmail from "../../mail/sendMail";

const MessageParser = ({ children, actions, handleMessageCount,setData,data }) => {


  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateMobile = (number) => {
    const pattern = /^[6789]\d{9}$/;
    return pattern.test(number);
  };



  const parse = (message) => {
    const { checker } = children.props.state;
    console.log(message)
    console.log(checker)
   

    if(checker==="Branch"){
      return actions.initialMessage()
    }

    if (checker === "Name") {
      if (message.length <= 0) {
        actions.nameErrorMessage("Name");
      } else {
        actions.afterNameMessage();
        setData((prevUserData) => ({
          ...prevUserData,
          name: message,
        }));
      }
    }
    if (checker === "Email") {
      if (message.length <= 0) {
        actions.nameErrorMessage("Email");
      } else if (validateEmail(message)) {
        actions.afterEmailMessage();
        setData((prevUserData) => ({
          ...prevUserData,
          email: message,
        }));
      } else {
        // const invalidEmailMessage = createChatBotMessage("Please enter a valid email.");
        actions.nameErrorMessage("Email");
      }
    }
    if (checker === "Mobile") {
      if (message.length < 10) {
        actions.mobileErrorMessage();
      } else if (validateMobile(message)) {
        actions.afterMobileMessage();
        setData((prevUserData) => ({
          ...prevUserData,
          mobile: message,
        }));
      } else {
        actions.mobileErrorMessage();
      }
    }

    if (checker === "Location") {
      actions.afterLocationMessage();
      setData((prevUserData) => ({
        ...prevUserData,
        location: message,
      }));
      sendEmail({...data,location:message})
    }
  };
 

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions: actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
