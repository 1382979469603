import React, { useState } from "react";
import graduation from "../../Images/icons/home-img2.png";
import "./home.css";
import arrow from "../../Images/icons/arrow.png";
import abstract from "../../Images/icons/abstract2.png";
import EnquiryForm from "./floatingMenu/EnquiryForm";

function Home() {
  const [isEnquiry, setIsEnquiry] = useState(false);
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 p-0">
          <div className="text-content">
            <h1>
              Discover Your Future by{" "}<br></br>
              <span className="highlight">Studying Abroad</span> for a<br></br>
              Life-Changing Adventure!
            </h1>
            <p>
              Find your perfect path with our tailored education counselling
              services, guiding you step by step for admission to top
              universities.
            </p>
            <div className="home-btn">
              <button
                className="apply-button"
                onClick={() => setIsEnquiry(!isEnquiry)}
              >
                Apply Online <img src={arrow} className="px-2" />{" "}
              </button>
            </div>
            <div className="social-icons">
              <a href="#">
                <i className="fab fa-facebook-f f-color"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter t-color"></i>
              </a>
              <a
                href="https://www.instagram.com/einstrostudyabroad/"
                target="_blank"
              >
                <i className="fab fa-instagram i-color"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 p-0 text-center">
          <div className="graduation-banner">
            {/* <div>
              <img src={abstract} className="img-fluid"/>
            </div> */}
            <div className="image-content text-center">
              <img src={graduation} alt="Graduation" loading="lazy"/>
            </div>
          </div>
        </div>
      </div>
      <EnquiryForm isEnquiry={isEnquiry} setIsEnquiry={setIsEnquiry} />
    </div>
  );
}

export default Home;
