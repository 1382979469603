import React, { useEffect, useState } from 'react'
import './chat.css'
import Chatbot, { createChatBotMessage } from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from './config'
import MessageParser from './MessageParser'
import ActionProvider from './ActionProvider'
import chatIcon from './bot1.png'
import mini from '../../../Images/icons/mini.png'
import close from '../../../Images/icons/close.png'

function Chat() {
    const [showChatBot,setShowChatBot]= useState(false)
    const [data,setData]= useState({
      branch:"",
      degree:"",
      country:""
    })

    console.log(data)

    const [messageCount, setMessageCount] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setShowChatBot(true);
    }, 10000);
  }, []);

  const handleMessageCount = (count) => {
    setMessageCount(count);
  };

 
  return (
    <div className='chat-main'>
      {
         showChatBot && <div className='chat-minimize-btn'>
         <img src={mini} onClick={()=>setShowChatBot(!showChatBot)}/>
         <img src={close} onClick={()=>setShowChatBot(!showChatBot)}/>
       </div>
      }
      
        {
            showChatBot &&  <Chatbot
            config={config}
            messageParser={(props) => <MessageParser {...props} handleMessageCount={handleMessageCount} setData={setData} data={data}/>}
            actionProvider={(props)=><ActionProvider {...props} setShowChatBot={setShowChatBot} setData={setData} data={data}/> }
            />
        }
       
        <div className='chat-icon' onClick={()=>setShowChatBot(!showChatBot)}>
            <div className='noti-parent'>
            <img src={chatIcon}/>
            {/* {
                messageCount>0 &&  <span className='notify-msg'>{messageCount}</span>
            } */}
           
            </div>
        </div>
    </div>
  )
}

export default Chat