import React from 'react'
import { motion } from "framer-motion";

function FormAnime({children}) {
    const animations = {
        initial:{ opacity: 0, y: -50 },
        animate:{ opacity: 1, y: 0 },
        transition:{ duration: 0.5 },
        exit: { opacity: 1, y: -50 },
      };
  return (
    <>
    <motion.div
        variants={animations}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        {children}
      </motion.div>
    </>
  )
}

export default FormAnime