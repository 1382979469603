import { motion, useScroll } from "framer-motion";
import React from "react";
import './scroll.css'

function ScrollAnime() {
  const { scrollYProgress } = useScroll();
  return (
    <motion.div className="progress-bar" style={{ scaleX: scrollYProgress }}/>
  );
}

export default ScrollAnime;
