import React, { useEffect, useState } from "react";
import EnquiryForm, { countryOptions } from "../floatingMenu/EnquiryForm";
import { Label } from "reactstrap";
import Select from "react-select";
import "./apply.css";
import toast from "react-hot-toast";
import sendEmail from "../../mail/sendMail";
import sendApplyEmail from "../../mail/sendApplyEmail";
import applyImage from "../../../Images/service/apply-image.png"

export const counsellingOption = [
  { label: "In-person", value: "In-person" },
  { label: "Virtual Counselling", value: "Virtual Counselling" },
];

export const studyLevelOption = [
  { label: "Undergraduate", value: "Undergraduate" },
  { label: "Postgraduate", value: "Postgraduate" },
  { label: "Doctorate", value: "Doctorate" },
  { label: "University Preparation", value: "University Preparation" },
];

export const fundOptions = [
  { label: "Self-funded", value: "Self-funded" },
  { label: "Parents", value: "Parents" },
  { label: "Seeking Scholarship", value: "Seeking Scholarship" },
  {
    label: "Seeking Government Scholarship",
    value: "Seeking Government Scholarship",
  },
  { label: "Bank Loan", value: "Bank Loan" },
  { label: "Other", value: "Other" },
  { label: "Employer Scholarship", value: "Employer Scholarship" },
];

export const inputOptionStyles = {
  container: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      width: "310px",
      borderColor: "#dd0e3a",
      border: "2px solid #8f14094d",
      borderRadius: "10px",
    };
  },
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: "white",
    cursor: "pointer",
    minHeight: "40px",
    borderColor: isFocused ? "#8f1409" : "rgb(143, 20, 9,0.3)",
    boxShadow: isFocused ? "10px 10px 20px 0 rgba(0, 94, 184, 0.05)" : "none",
    ":hover": {
      borderColor: "#dd0e3a",
      boxShadow: "10px 10px 20px 0 rgba(0, 94, 184, 0.05)",
    },
    ":focus": {
      border: "2px solid #8f1409",
      background: "white",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      cursor: "pointer",
      width: "100%",
      backgroundColor: isFocused ? "" : "white",
      color: isFocused ? "black" : "",
      ":hover": {
        backgroundColor: "white",
        color: "#dd0e3a",
      },
      fontSize: "14px",
    };
  },
  placeholder: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: "#6c757d",
      fontSize: "14px",
    };
  },
  indicatorSeparator: () => {
    return {
      display: "none",
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontFamily: "Metropolis-SemiBold",
      color: "code-red",
    };
  },
};

function Apply() {
  const [intakeOption, setIntakeOptions] = useState([]);
  const [country, setCountry] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateMobile = (number) => {
    const pattern = /^[6789]\d{9}$/;
    return pattern.test(number);
  };

  const [newData, setNewData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    country: "",
    plan_date: "",
    counselling: "",
    degree: "",
    fund: "",
    termsAndPolicy:false,
    reachMeOut:false
  });

  const handleIntakeOption = () => {
    const now = new Date();
    const endYear = now.getFullYear() + 5; // 5 years from now
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const newIntakeOptions = []; // Temporary array to store options

    // Loop through the years
    for (let year = now.getFullYear(); year <= endYear; year++) {
      // If it's the current year, start from the current month
      const startMonth = year === now.getFullYear() ? now.getMonth() : 0;

      // Loop through the months
      for (let month = startMonth; month < 12; month++) {
        newIntakeOptions.push({
          label: `${monthNames[month]}-${year}`,
          value: `${monthNames[month]}-${year}`,
        });
      }
    }

    setIntakeOptions(newIntakeOptions); // Update state once
  };

  useEffect(() => {
    handleIntakeOption();
  }, []);

  const handleSelect = (name,value) => {
    if(value){
        setNewData({...newData,[name]:value})
    }
  };

  const handleSubmit=()=>{
        const {
          firstName,
          lastName,
          email,
          mobile,
          counselling,
          country,
          degree,
          fund,
          plan_date,
          termsAndPolicy,
          reachMeOut,
        } = newData;

        if(firstName===""){
            return toast.error("First name Required")
        }
        if(lastName===""){
            return toast.error("Last name Required")
        }
        if(email===""){
            return toast.error("Email Required")
        }
        if(!validateEmail(email)){
            return toast.error("Invalid Email")
        }
        if(!validateMobile(mobile)){
            return toast.error("Invalid Mobile Number")
        }
        if(country===""){
            return toast.error("Please Select Destination")
        }
        if(plan_date===""){
            return toast.error("Plan to Study Required")
        }
        if(counselling===""){
            return toast.error("Counselling Required")
        }
        if(degree===""){
            return toast.error("Study Level Required")
        }
        if(fund===""){
            return toast.error("Fund Required")
        }

        if(!termsAndPolicy){
            return toast.error("Please accept the terms and policy")
        }
        if(!reachMeOut){
            return toast.error("Please accept the reach me out")
        }

        newData.name = `${firstName} ${lastName}`

        sendApplyEmail(newData)
        toast.success("We will contact you soon! Thank You")
        setNewData({
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            country: "",
            plan_date: "",
            counselling: "",
            degree: "",
            fund: "",
            termsAndPolicy:false,
            reachMeOut:false
        })

  }

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div className="apply-head">
            <h3>
              <span className="code-red">Einstro</span> can help You
            </h3>
            <hr className="head-under"></hr>
            <p>
              Share your details, and our counsellors will help you find the
              ideal course, country, university, and scholarships!
            </p>
          </div>
          <div className="container-fluid p-0">
            <div className="form-box">
              <div className="name-sec">
                <div className="pe-3">
                  <label>
                    First name <span className="code-red font-bold">*</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      className="apply-input-size"
                      value={newData.firstName}
                      onChange={(e) =>
                        setNewData({ ...newData, firstName: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="pe-3">
                  <label>
                    Last name <span className="code-red font-bold">*</span>
                  </label>
                  <div>
                    <input type="text" className="apply-input-size"  value={newData.lastName}
                      onChange={(e) =>
                        setNewData({ ...newData, lastName: e.target.value })
                      }/>
                  </div>
                </div>
              </div>
              <div className="font-semi mt-4">
                <label className="">
                  Email address <span className="code-red font-bold">*</span>
                </label>
                <div>
                  <input type="text" className="apply-input-size-full"  value={newData.email}
                      onChange={(e) =>
                        setNewData({ ...newData, email: e.target.value })
                      }/>
                </div>
              </div>
              <div className="font-semi mt-4">
                <label className="">
                  Mobile <span className="code-red font-bold">*</span>
                </label>
                <div>
                  <input
                    value="+91"
                    className="apply-input-size-mobile"
                    disabled
                    type="text"
                  />
                  <input
                    className="apply-input-size-mobile-full"
                    type="phone"
                    value={newData.mobile}
                    onChange={(e) =>
                      setNewData({ ...newData, mobile: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="font-semi mt-5">
                <div className="d-flex justify-space-around">
                  <div className="pe-3">
                    <label className="">
                      Your preferred study destination{" "}
                      <span className="code-red font-bold">*</span>
                    </label>
                    <div>
                      <Select
                        className="apply-select-box-size"
                        styles={inputOptionStyles}
                        options={countryOptions}
                        value={countryOptions.filter((list)=>list.value===newData.country)}
                        onChange={(e) => handleSelect('country',e.value)}
                      />
                    </div>
                  </div>
                  <div className="pe-3">
                    <label className="">
                      When do you plan to study?
                      <span className="code-red font-bold">*</span>
                    </label>
                    <div>
                      <Select
                        className="apply-select-box-size"
                        styles={inputOptionStyles}
                        options={intakeOption}
                        value={intakeOption.filter((list)=>list.value===newData.plan_date)}
                        onChange={(e) => handleSelect('plan_date',e.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="font-semi mt-5">
                <div className="d-flex justify-space-around">
                  <div className="pe-3">
                    <label className="">
                      Preferred mode of counselling
                      <span className="code-red font-bold">*</span>
                    </label>
                    <div>
                      <Select
                        className="apply-select-box-size"
                        styles={inputOptionStyles}
                        options={counsellingOption}
                        value={counsellingOption.filter((list)=>list.value===newData.counselling)}
                        onChange={(e) => handleSelect('counselling',e.value)}
                      />
                    </div>
                  </div>
                  <div className="pe-3">
                    <label className="">
                      Preferred Study Level
                      <span className="code-red font-bold">*</span>
                    </label>
                    <div>
                      <Select
                        className="apply-select-box-size"
                        styles={inputOptionStyles}
                        options={studyLevelOption}
                        value={studyLevelOption.filter((list)=>list.value===newData.degree)}
                        onChange={(e) => handleSelect('degree',e.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="font-semi mt-4">
                <label className="">
                  How would you fund your education?
                  <span className="code-red font-bold">*</span>
                </label>
                <div>
                  <Select
                    className="apply-select-box-size"
                    styles={inputOptionStyles}
                    options={fundOptions}
                    value={fundOptions.filter((list)=>list.value===newData.fund)}
                        onChange={(e) => handleSelect('fund',e.value)}
                  />
                </div>
              </div>
              <div className="font-light mt-4">
                <div>
                  <input type="checkbox" className="me-3" checked={newData.termsAndPolicy} onChange={(e)=>setNewData({...newData,termsAndPolicy:e.target.checked})}/>
                  <label>
                    I agree to Einstro Study Abroad <a href="">Terms</a> and{" "}
                    <a href="">privacy policy</a>{" "}
                  </label>
                </div>
              </div>
              <div className="font-light mt-4">
                <div>
                  <input type="checkbox" className="me-3" checked={newData.reachMeOut} onChange={(e)=>setNewData({...newData,reachMeOut:e.target.checked})}/>
                  <label>
                    Please reach out to me via phone, email, or SMS to assist
                    with my inquiry.
                  </label>
                </div>
              </div>
              <div className="mt-4">
                <button className="apply-page-btn" onClick={()=>handleSubmit()}>Apply</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div className="text-center">
          <img src={applyImage} className="right-img"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Apply;
