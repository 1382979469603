export const testimonialData = [
  {
    name: "Balaji Murugan",
    image: "/testimonial/balaji.JPG",
    university: "Ravensbourne University, London",
    country: "United Kingdom",
    course: "MSc in Cybersecurity,",
    review:
      "My experience with Einstro Study Abroad has been nothing short of great. From shortlisting universities to securing scholarships, they were prompt and patient throughout the process. We were guided really well, and I had my visa for the United Kingdom in hand within 2 weeks (standard processing time). Their team and system are well-built, and and though there were minor delays in communication, they delivered and obtained my visa on time. I highly recommend Einstro Study Abroad if you’re looking to study abroad!",
  },
  {
    name: "Vahin Sankar",
    image: "/testimonial/Vahin Sankar.jpeg",
    university: "University of West London",
    country: "United Kingdom",
    course: "MSc in Cyber Security,",
    review:
      "I had a wonderful experience with Einstro Study Abroad, especially with my counselor. He guided me through the entire application process and my mock interview, which ultimately led to my successful visa approval for pursuing my master's in the United Kingdom. My counselor provided valuable insights, addressed all my concerns, and thoroughly prepared me for the interview. I am grateful for his expertise and highly recommend Einstro Study Abroad at OMR!",
  },
  {
    name: "Shyam Chandran",
    image: "/testimonial/Shyam Chandran.jpeg",
    university: "Dublin Business School",
    country: "Ireland",
    course: "Msc in Supply Chain Management,",
    review:
      "The support I received during my application process to Dublin Business School was outstanding. I received an offer letter within two days of applying, and I enrolled in the MSc Supply Chain Management program. The visa process was critical, and the Einstro Study Abroad visa officer nailed it with the correct documentation and a hassle-free process. I would like to thank the entire team for fulfilling my dreams abroad.",
  },
  {
    name: "Bezaalin Rajkumar",
    image: "/testimonial/Bezaalin Rajkumar.jpeg",
    university: "Algoma University",
    country: "Canada",
    course: "Bachelor of Fine Arts,",
    review: "I had an excellent overall experience with Einstro Study Abroad. I was admitted to Algoma University in Ontario for my bachelor's program. They provided comprehensive guidance and assistance from the beginning stages of the visa application process. During the entire period, my counselor at Einstro Study Abroad was a tremendous help. I heartily urge anyone considering studying overseas to advantage of their services.",
  },
  {
    name: "Bhavagnu Reddy ",
    image: "/testimonial/reddy1.jpeg",
    university: "University of Central Missouri",
    country: "USA",
    course: "MS in Computer Science,",
    review:
      "I came here to receive IELTS coaching and subsequently sought guidance on studying in the United States. My counselor guided me from the beginning, step by step, and I applied to the five best universities with affordable tuition fees. All went smoothly, and finally, I got my USA student visa three months before the start date. I would like to thank the Einstro Study Abroad team for making my dreams come true.",
  },
  {
    name: "Lekhaj Reddy",
    image: "/testimonial/lekhaj.jpeg",
    university: "University of Central Missouri",
    country: "USA",
    course: "MS in Computer Science,",
    review:
      "The Einstro Study Abroad team helped me with my complete admission process to the University of Central Missouri. The counseling was free. They provided me with numerous options in the USA, along with precise guidance and fees. I got my visa and am happy to recommend Einstro for IELTS and study abroad admission counseling. I didn't find any difficulty throughout this process and would like to thank the team at Einstro Study Abroad.",
  },
  {
    name: "Kavin Maran",
    image: "/testimonial/kavin.jpeg",
    university: "LA Trobe University",
    country: "Australia",
    course: "BSc in Agriculture,",
    review:
      "I joined Einstro for IELTS coaching, and they helped me secure a satisfactory score. Additionally, they offered guidance on studying in Australia. La Trobe University admitted me to the Bachelor's Program. My mentor was consistently in touch with me and provided feedback on my process. I am so grateful to the team at Einstro Study Abroad.",
  },
  {
    name: "Maria D'Cruze",
    image: "/testimonial/maria.jpeg",
    university: "Conestoga College",
    country: "Canada",
    course: "Post Graduate in Financial Planning,",
    review:
      "I had 5 years of experience and wanted to study in Canada. It was a big decision in my life. I also want to take my husband before the immigration rules change. So, I asked Einstro to study abroad in the OMR branch, and the team helped me get admission to Conestoga College. At every stage, they guided me and provided enough information. I obtained my student visa and traveled to Canada with expert guidance through the admissions process. Afterwards, they assisted my spouse with his visa application, leading to his successful arrival in Canada.",
  },
  {
    name: "Nithya Nandhini",
    image: "/testimonial/Nithiya.jpg",
    university: "Algoma University",
    country: "Canada",
    course: "Graduate certificate in information technology,",
    review:
      "I recently had a fantastic experience with Einstro Study Abroad. My counselor guided me from the initial university shortlisting based on my specific interests all the way to preparing for my visa interview. They took the time to understand my individual needs and provided personalized guidance, helping me make the right choices during my master's abroad journey. I highly recommend Einstro Study Abroad for anyone seeking professional and personalized support in pursuing their higher education goals.",
  },
  {
    name: "Nivedhitha",
    image: "/testimonial/nivedhitha.jpg",
    university: "Trent University",
    country: "Canada",
    course: "Post graduate in Human resources,",
    review:
      "Einstro Study Abroad provided excellent service throughout my application process. Their knowledgeable team, efficient communication, and attention to detail ensured a smooth and successful experience. My counselor's outstanding expertise as an overseas education consultant made my journey stress-free and successful. Her guidance and genuine commitment to my aspirations were truly commendable. I highly recommend Einstro Study Abroad for anyone seeking reliable and proficient support in pursuing education abroad.",
  },
];
