import React, { useEffect, useState } from "react";
import "./blog.css";
import blog from "./../../../Images/service/blogg.png";
import { blogData } from "../../StudyJsonData/blogData";
import {
  A11y,
  Autoplay,
  EffectCards,
  EffectCoverflow,
  EffectCube,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";

import blogImg from "../../../Images/service/blogbg.png";
import plane from "../../../Images/icons/plane.png";
import blogGroup from "../../../Images/service/blogGroup.jpg";
import truncate from "lodash/truncate";
import facebook from "../../../Images/icons/social/facebook.png";
import insta from "../../../Images/icons/social/instagram.png";
import youtube from "../../../Images/icons/social/youtube.png";
import twitter from "../../../Images/icons/social/twitterx.png";
import linkedin from "../../../Images/icons/social/linkedin.png";

import whatsapp from "../../../Images/icons/social/whatsapp.png";
import { useNavigate } from "react-router-dom";
import SectionAnime from "../Animation/SectionAnimation/SectionAnime";

function Blog() {
  const navigate = useNavigate();
  const [bloglist, setBlogList] = useState(blogData);

  useEffect(() => {
    setBlogList(blogData);
    window.scrollTo(0, 0);
  }, []);
  return (
    <SectionAnime>
      <div className="container-fluid p-0">
        <div className="blog-head">
          <div className="image-wrapper">
            <img src={blogImg} loading="lazy"/>
            <img src={blogImg} loading="lazy"/>
          </div>
          {/* <div className="blog-heading">
            <h1>Blog</h1>
          </div> */}
          <div className="blog-plane">
            <img src={plane} loading="lazy"/>
          </div>
        </div>
        <div className="container my-5">
          <div className="row ">
            <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
              <div className="row">
                {bloglist.map((list) => {
                  return (
                    <div
                      className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
                      onClick={() => navigate(`/blog/${list?.routeId}`)}
                    >
                      <div className="blog-card">
                        <div>
                          <img src={list?.image} className="blog-img" loading="lazy"/>
                        </div>
                        <div className="blog-card-content">
                          <h5>{list?.title}</h5>
                          <p>{truncate(list.description, { length: 90 })}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 right-fix">
              <div className="row">
                <div className="col-12">
                  <div className="connect-card">
                    <h4>
                      Let's Connect<hr></hr>
                    </h4>
                    <div className="connect-social">
                      <div>
                        <a
                          target="_blank"
                          href="https://api.whatsapp.com/send?phone=918925565861&text=Hello%2C%20I%20want%20to%20Study%20Abroad.%2C%20Please%20Contact%20me"
                        >
                          {" "}
                          <img src={whatsapp} loading="lazy"/>
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.facebook.com/einstroacademy"
                          target="_blank"
                        >
                          {" "}
                          <img src={facebook} loading="lazy"/>
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.youtube.com/@einstroacademy"
                          target="_blank"
                        >
                          {" "}
                          <img src={youtube} loading="lazy"/>
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://www.instagram.com/einstrostudyabroad/"
                          target="_blank"
                        >
                          <img src={insta} loading="lazy"/>
                        </a>
                      </div>
                      <div>
                        <a href="https://www.linkedin.com/showcase/einstrostudyabroad/" target="_blank">
                          <img src={linkedin} loading="lazy"/>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="article-card">
                    <h4>
                      Recent Article<hr></hr>
                    </h4>
                    <div className="">
                      <Swiper
                        direction={"vertical"}
                        grabCursor={true}
                        pagination={false}
                        slidesPerView={"3"}
                        loop
                        modules={[Pagination, Autoplay]}
                        className="myBlogSwiper"
                        autoplay={{
                          delay: 2000,
                          disableOnInteraction: false,
                        }}
                      >
                        {bloglist.map((list) => {
                          return (
                            <SwiperSlide
                              className="blog-slide"
                              onClick={() => navigate(`/blog/${list?.routeId}`)}
                            >
                              <div className="article-list">
                                <div className="px-3">
                                  <img src={list.image} loading="lazy"/>
                                </div>
                                <div className="px-2">
                                  <h5 className="article-des">
                                    {truncate(list.description, {
                                      length: 130,
                                    })}
                                  </h5>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionAnime>
  );
}

export default Blog;
