import { createChatBotMessage,createClientMessage } from 'react-chatbot-kit';
import StudyOption from './StudyOption';
import DegreeOption from './DegreeOption';
import BranchOption from './BranchOption';
import Details from './Details';
import BachelorOption from './BachelorOption';

const config = {
    botName:"Einstro Bot",
    initialMessages: [
    createChatBotMessage(`Welcome to Einstro Study Abroad`,{
        widget:'startBtn'
    }),
],
  widgets:[
    {
        widgetName:"startBtn",
        widgetFunc:(props)=><StudyOption {...props}/>
    },
    {
        widgetName:"degreeBtn",
        widgetFunc:(props)=><DegreeOption {...props}/>
    },
    {
        widgetName:"branchBtn",
        widgetFunc:(props)=><BranchOption {...props}/>
    },
    {
        widgetName:"bachelorBtn",
        widgetFunc:(props)=><BachelorOption {...props}/>
    },
    {
        widgetName:"details",
        widgetFunc:(props)=><Details {...props}/>
    }
  ]
};

export default config;