import React, { useEffect, useState } from "react";
import FormAnime from "../Animation/FormAnimation/FormAnime";
import Select from "react-select";
import { Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import contact from "../../../Images/icons/contactus2.png";
import send from "../../../Images/icons/send.png";
import address from "../../../Images/icons/location-red.png";
import mail from "../../../Images/icons/mail-red.png";
import phone from "../../../Images/icons/phone-red.png";
import facebook from "../../../Images/icons/social/facebook.png";
import insta from "../../../Images/icons/social/instagram.png";
import youtube from "../../../Images/icons/social/youtube.png";
import twitter from "../../../Images/icons/social/twitterx.png";
import linkedin from "../../../Images/icons/social/linkedin.png";
import whatsapp from "../../../Images/icons/social/whatsapp.png";
import "./contact.css";
import {
  branchOption,
  countryOptions,
  customStyles,
  degreeOption,
} from "../floatingMenu/EnquiryForm";
import Testimonial from "../Testimonial";
import toast from "react-hot-toast";
import sendEmail from "../../mail/sendMail";

function Contact() {
  const [isContact, setIsContact] = useState(false);
  const [branchOptions, setBranchOptions] = useState(branchOption);
  const [enquiryData, setEnquiryData] = useState({
    country: "",
    degree: "",
    branch: "",
    name: "",
    email: "",
    mobile: "",
    location: "",
  });

  const handleDegree = (e) => {
    console.log(e);
    if (e.value === "Bachelor's Degree") {
      setEnquiryData({ ...enquiryData, degree: e.value });
      let filterResult = branchOptions.filter((op) => op.value !== "MBA");
      setBranchOptions([...filterResult]);
    } else {
      setEnquiryData({ ...enquiryData, degree: e.value });
      setBranchOptions([...branchOption]);
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateMobile = (number) => {
    const pattern = /^[6789]\d{9}$/;
    return pattern.test(number);
  };

  const handleSubmit = () => {
    if (enquiryData.country === "") {
      return toast.error("Country Required");
    }
    if (enquiryData.degree === "") {
      return toast.error("Select Degree Field");
    }
    if (enquiryData.branch === "") {
      return toast.error("Select Branch Field");
    }
    if (enquiryData.name === "") {
      return toast.error("Name required");
    }
    if (enquiryData.email === "") {
      return toast.error("Email required");
    }
    if (!validateEmail(enquiryData.email)) {
      return toast.error("Please Enter valid Email");
    }
    if (!validateMobile(enquiryData.mobile)) {
      return toast.error("Please Enter valid Mobile Number");
    }
    if (enquiryData.mobile === "") {
      return toast.error("Mobile required");
    }
    if (enquiryData.location === "") {
      return toast.error("Location required");
    }
    sendEmail(enquiryData)
    toast.success("Thank you for reaching out! We'll be in touch shortly.");
    setEnquiryData({
      country: "",
      degree: "",
      branch: "",
      name: "",
      email: "",
      mobile: "",
      location: "",
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container  px-2 my-5">
      <div className="contact_head">
        <h1>Contact Us</h1>
        <h3>We are here to assist you</h3>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 py-5">
          <div class="card contact_card">
            <div class="card-body">
              <h5 class="card-title m-0">
                <img src={address} className="loc-ico" loading="lazy"/>
                Einstro Study Abroad
              </h5>
              <p className="contact-text">
                No. 4/636 A, VOC Street, PTC, Thuraipakkam (OMR),<br></br>{" "}
                Chennai - 600 097,
                <br /> Tamil Nadu, India.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 py-5">
          <div class="card contact_card">
            <div class="card-body">
              <h5 class="card-title m-0">
                <img src={phone} className="loc-ico" loading="lazy"/>
                <a href="tel:8925565861" className="contact-tel">
                  8925565861
                </a>
              </h5>
              <p className="contact-text">
                Monday - Saturday<br></br>
                10AM to 6PM IST
              </p>
              <h5 class="card-title pt-2 text-dark">
                <img src={mail} className="loc-ico" loading="lazy"/>
                <a
                  href="mailto:info@einstrostudyabroad.com"
                  className="contact-link"
                >
                  info@einstrostudyabroad.com
                </a>
              </h5>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 py-5">
          <div className="card contact_card">
            <div className="card-body">
              <div className="connect-social my-5">
                <div>
                  <a
                    target="_blank"
                    href="https://api.whatsapp.com/send?phone=918925565861&text=Hello%2C%20I%20want%20to%20Study%20Abroad.%2C%20Please%20Contact%20me"
                  >
                    {" "}
                    <img src={whatsapp} loading="lazy"/>
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.facebook.com/einstroacademy"
                    target="_blank"
                  >
                    {" "}
                    <img src={facebook} loading="lazy"/>
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.youtube.com/@einstroacademy"
                    target="_blank"
                  >
                    {" "}
                    <img src={youtube} loading="lazy"/>
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/einstrostudyabroad/"
                    target="_blank"
                  >
                    <img src={insta} loading="lazy"/>
                  </a>
                </div>
                <div>
                  <a href="https://www.linkedin.com/showcase/einstrostudyabroad/" target="_blank">
                    <img src={linkedin} loading="lazy"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 py-5">
          <div class="card contact_card">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </p>
              <a href="#" class="btn btn-primary">
                Go somewhere
              </a>
            </div>
          </div>
        </div> */}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 p-0 my-4">
            <div className="map-des">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.6279438626657!2d80.23056887454602!3d12.931617815754661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5251a364e8ed23%3A0xd5877cd210dace7e!2sEinstro%20Academy%20-%20IELTS%20Coaching%20%2C%20Study%20Abroad%2C%20Spoken%20English!5e0!3m2!1sen!2sin!4v1719816167805!5m2!1sen!2sin"
                className="responsive-iframe"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 p-0 my-4">
            <div className="container form-font form-border">
              <div className="row">
                <h5 className="form-heading">Free Consultation</h5>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <Label for="category" className="fm-lb">
                    Country
                  </Label>
                  <Select
                    id="category"
                    name="name"
                    placeholder="Choose Country"
                    options={countryOptions}
                    styles={customStyles}
                    onChange={(e) =>
                      setEnquiryData({ ...enquiryData, country: e.value })
                    }
                    value={countryOptions.filter(
                      (op) => op.value === enquiryData.country
                    )}
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <Label for="country" className="fm-lb">
                    Academic Degree
                  </Label>
                  <Select
                    id="country"
                    name="name"
                    placeholder="Choose Degree"
                    options={degreeOption}
                    styles={customStyles}
                    onChange={(e) => handleDegree(e)}
                    value={degreeOption.filter(
                      (op) => op.value === enquiryData.degree
                    )}
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <Label for="country" className="fm-lb">
                    Branch
                  </Label>
                  <Select
                    id="country"
                    name="name"
                    placeholder="Choose Branch"
                    options={enquiryData.degree?branchOptions:[]}
                    styles={customStyles}
                    onChange={(e) =>
                      setEnquiryData({ ...enquiryData, branch: e.value })
                    }
                    value={branchOptions.filter(
                      (op) => op.value === enquiryData.branch
                    )}
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-2">
                  <Label for="name" className="fm-lb">
                    Name
                  </Label>
                  <Input
                    id="name"
                    name="name"
                    placeholder="Enter Name"
                    type="text"
                    onChange={(e) =>
                      setEnquiryData({ ...enquiryData, name: e.target.value })
                    }
                    valid={enquiryData.name.length > 3}
                    value={enquiryData.name}
                    // valid
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12  my-2">
                  <Label for="email" className="fm-lb">
                    Email
                  </Label>
                  <Input
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    type="email"
                    onChange={(e) =>
                      setEnquiryData({ ...enquiryData, email: e.target.value })
                    }
                    valid={
                      enquiryData.email.length > 0 &&
                      validateEmail(enquiryData.email)
                    }
                    value={enquiryData.email}
                    // valid
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-2">
                  <Label for="mobile" className="fm-lb">
                    Mobile
                  </Label>
                  <Input
                    id="mobile"
                    name="mobile"
                    placeholder="Enter Mobile"
                    type="phone"
                    onChange={(e) =>
                      setEnquiryData({ ...enquiryData, mobile: e.target.value })
                    }
                    valid={
                      enquiryData.mobile.length <= 10 &&
                      validateMobile(enquiryData.mobile)
                    }
                    value={enquiryData.mobile}
                    // valid
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-2">
                  <Label for="name" className="fm-lb">
                    Location
                  </Label>
                  <Input
                    id="location"
                    name="location"
                    placeholder="Enter Location"
                    type="text"
                    onChange={(e) =>
                      setEnquiryData({
                        ...enquiryData,
                        location: e.target.value,
                      })
                    }
                    valid={enquiryData.location.length > 0}
                    value={enquiryData.location}
                    // valid
                  />
                </div>
              </div>
              <div className="text-center">
                <button className="submit-btn" onClick={() => handleSubmit()}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Testimonial />
    </div>
  );
}

export default Contact;
