import React from "react";
import { branchOption } from "../floatingMenu/EnquiryForm";

function BranchOption({ actions}) {
  const options = branchOption.map((list, i) => {
    return {
      text: list.label,
      handler: () => actions.handleBranchOption(list.label),
      id: i + 1,
    };
  });

  

  return (
    <div className="options-container">
      <div className="sec-msg">Choose Branch</div>
      {options.map((option) => (
        <span>
          <button
            key={option.id}
            onClick={option.handler}
            className="option-button"
          >
            {option.text}
          </button>
        </span>
      ))}
    </div>
  );
}

export default BranchOption;
