import React from "react";

function Details() {
  return (
    <div className="options-container">
      <div className="sec-msg">Please enter your name</div>
    </div>
  );
}

export default Details;
