import React, { useEffect } from 'react'
import Home from './Home'
import University from './University'
import Service from './Service'
import Country from './Country'
import Testimonial from './Testimonial'
import SectionAnime from './Animation/SectionAnimation/SectionAnime'

function MainPage() {
  
    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])
  return (
    <>
    <SectionAnime>
     <Home/>
     <University/>
     <Service/>
     <Country/>
     <Testimonial/>
     </SectionAnime>
    </>
  )
}

export default MainPage