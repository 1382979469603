import React from 'react'

function DegreeOption({actions}) {
    const options = [
        { text: "Bachelor's", handler: () => actions.handleDegreeOption("Bachelor's"), id: 1 },
        { text: "Master's", handler: () => actions.handleDegreeOption("Master's"), id: 2 },
        { text: "Doctorate", handler: () => actions.handleDegreeOption("Doctorate"), id: 3 },
        { text: "PhD", handler: () => actions.handleDegreeOption("PhD"), id: 4 }
    ];

      
  return (
    <div className="options-container">
        <div className='sec-msg'>
            Choose Degree
        </div>
    {options.map((option) => (
     <span>
         <button key={option.id} onClick={option.handler} className="option-button">
        {option.text}
      </button>
     </span>
    ))}
  </div>
  )
}

export default DegreeOption