import React from 'react';

const ActionProvider = ({ createChatBotMessage,setState, children ,setShowChatBot,setData,data}) => {
    const handleStudyOption = (country) => {
        const usermessage= createChatBotMessage(`${country}`,{
            type:'user'
        })
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages,usermessage],
            checker:'country'
        }));

        setData((prev)=>({
            ...prev,
            country:country
        }))
        const message = createChatBotMessage(`You chose to study in ${country}.`,{
            widget:'degreeBtn',
            country:country
        });

        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
        }));
    };

    const handleClientMessage=(text)=>{
        const update = createChatBotMessage(`${text}.`);
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages,update],
        }));
    }

    const handleDegreeOption =(degree)=>{
        const usermessage= createChatBotMessage(`${degree}`,{
            type:'user'
        })
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages,usermessage],
        }));
        setData((prev)=>({
            ...prev,
            degree:degree
        }))
        const message = createChatBotMessage(`You chose ${degree} Degree.`,{
            widget:degree==="Bachelor's"?'bachelorBtn':'branchBtn',
            degree:degree
        });

        setState((prev)=>({
            ...prev,
            messages:[...prev.messages,message],
        }))
    }

    const handleBranchOption =(branch)=>{
        const usermessage= createChatBotMessage(`${branch}`,{
            type:'user'
        })
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages,usermessage],
        }));
        if(branch!=='Others'){
            const message = createChatBotMessage(`You chose ${branch} Branch.`,{
                widget:'details',
                branch:branch
            });
            setData((prev)=>({
                ...prev,
                branch:branch
            }))
            updateState(message,"Name")

        }else{
            const message = createChatBotMessage(`Please Enter the Branch name`);
            updateState(message,"Branch")
        }

       
    }

    const initialMessage = ()=>{
        const message =createChatBotMessage('Please Enter Your Name')
        updateState(message, "Name")
    }

    const otherDegreeMessage =(item)=>{
        const message = createChatBotMessage(`Please enter branch`);
        updateState(message, `${item}`)
    }

    const nameErrorMessage =(item)=>{
        const message = createChatBotMessage(`Please enter Your ${item} Properly`);
        updateState(message, `${item}`)
    }

    const afterNameMessage = () => {
        const message = createChatBotMessage('Please enter your email');
        updateState(message, "Email")
    }

    const afterEmailMessage = () => {
        const message = createChatBotMessage("Please enter your mobile number.")
        updateState(message, "Mobile")
    }

    const afterMobileMessage = () => {
        const message = createChatBotMessage("Location")
        updateState(message, "Location")
    }

    const mobileErrorMessage = () => {
        const message = createChatBotMessage("Please enter a valid Mobile Number.")
        updateState(message, "Mobile")
    }

    const afterLocationMessage = () => {
        const thankYouMessage = createChatBotMessage("Thank you for submitting! We will Contact you soon", {
            withAvatar: true,
            className: 'bot-message',
        });
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, thankYouMessage],
        }));

        
        setTimeout(() => {
            setState((prev) => ({
                ...prev,
                messages: [],
            }));
            setShowChatBot(false)
        }, 2000);  // Clear chat after 2 seconds
    };


    const updateState = (message, checker) => {
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
            checker,
        }))
    }

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
            actions: {
                handleStudyOption,
                handleDegreeOption,
                handleClientMessage,
                handleBranchOption,
                afterNameMessage,
                afterEmailMessage,
                afterMobileMessage,
                mobileErrorMessage,
                afterLocationMessage,
                nameErrorMessage,
                otherDegreeMessage,
                initialMessage
              },
        });
      })}
    </div>
  );
};

export default ActionProvider;