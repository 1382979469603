import React from "react";
import { branchOption } from "../floatingMenu/EnquiryForm";

function BachelorOption({ actions}) {
  const options = branchOption.filter((list, i) => list.label!=="MBA");

  const bachelorOption = options.map((list,i)=>{
        return {
            text: list.label,
            handler: () => actions.handleBranchOption(list.label),
            id: i + 1,
          };
  })

  return (
    <div className="options-container">
      <div className="sec-msg">Choose Branch</div>
      {bachelorOption.map((option) => (
        <span>
          <button
            key={option.id}
            onClick={option.handler}
            className="option-button"
          >
            {option.text}
          </button>
        </span>
      ))}
    </div>
  );
}

export default BachelorOption;
