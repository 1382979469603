import React, { useState } from "react";
import "./findcourse.css";
import {
    Input,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  TabContent,
  TabPane,
} from "reactstrap";
import SortedArrayComponent, { CoursesList, studyDestinations, studyLevels, subBranches, subjects } from "./subjectAreaData";
import Select from "react-select";

const popularityOption =[
    {
        label:"IELTS score(Low to High)",
        value:"lowToHigh"
    },
    {
        label:"IELTS score(High to Low)",
        value:"hightToLow"
    },
    {
        label:"University Rankings",
        value:"rankings"
    },
    {
        label:"Next Intake",
        value:"nextIntake"
    },
]

function FindCourse() {
  const [isFilter, setIsFilter] = useState(false);
  const [isSubject, setIsSubject] = useState(false);
  const [subject,setSubject]=useState({id:0,name:"All"})
  const [activeTab, setActiveTab] = useState("1");
  const [selectedSubject,setSelectedSubject]= useState([])

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const subjectToggle =()=>{
    setIsSubject(!isSubject)
  }

  const handleSelectedSubject =(e,branch)=>{
    if(e.target.checked){
        setSelectedSubject([...selectedSubject,branch])
    }else{
        setSelectedSubject(selectedSubject.filter((item)=>item!==branch))
    }
  }

  const cancelFilter =()=>{
    setIsFilter(!isFilter)
    setSubject({id:0,name:"All"})
  }

 console.log(selectedSubject)
  return (
    <div className="container">
      <div className="course-head-part">
        <div>
          <h3 className="course-head">Discover Our courses</h3>
        </div>

        <div className="d-flex">
          <div className="filter-select">
            <Select options={popularityOption} placeholder="Sort by:" />
          </div>
          <button
            className="course-filter-btn"
            onClick={() => setIsFilter(!isFilter)}
          >
            Filter
          </button>
        </div>
      </div>
      <div className="row">
        {
            CoursesList.map((list)=>{
                return (
                  <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 my-3">
                    <div class="card course-card">
                      <div class="card-body">
                        <div className="course-title-details">
                          <h5 class="card-title course-title">{list.title}</h5>
                          <h6 class="card-subtitle mb-2 text-body-secondary">
                            {list.university}
                          </h6>
                        </div>
                        <hr className=""></hr>
                        <ul className="course-details-list">
                          {list.worldRanking && (
                            <li>THE world Ranking: {list.worldRanking}</li>
                          )}
                          {list.degree && <li> {list.degree}</li>}
                          {list.nextIntake && (
                            <li>Next Intake: {list.nextIntake}</li>
                          )}
                          {list.entryScore && (
                            <li>Entry Score: {list.entryScore}</li>
                          )}
                          {list.inr && <li>INR: {list.inr}</li>}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
            })
        }
            
      </div>
      <Offcanvas
        isOpen={isFilter}
        toggle={() => cancelFilter()}
        direction="end"
        className="course-canvas"
      >
        <OffcanvasHeader toggle={() => cancelFilter()}>
          <h3>Filter</h3>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="row">
            <div className="col-4 d-flex flex-column">
              <div className="filter-left sticky-top">
                <Nav id="courseTabs" tabs>
                  <NavItem
                    className={`${
                      activeTab === "1" ? "course-item active" : ""
                    }`}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <NavLink
                      className={`${
                        activeTab === "1" ? "active" : ""
                      } course-link`}
                    >
                      Domain
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={`${
                      activeTab === "2" ? "course-item active" : ""
                    }`}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    <NavLink
                      className={`${
                        activeTab === "2" ? "active" : ""
                      } course-link`}
                    >
                      Study Level
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={`${
                      activeTab === "3" ? "course-item active" : ""
                    }`}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    <NavLink
                      className={`${
                        activeTab === "3" ? "active" : ""
                      } course-link`}
                    >
                      Study Destinations
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
            <div className="col-8">
              <TabContent activeTab={activeTab} className="course_tab_content">
                <TabPane tabId="1">
                  <div>
                    <Input
                      placeholder="Select Subject Area"
                      readOnly
                      className="course_subject"
                      value={subject.name}
                      onClick={() => setIsSubject(!isSubject)}
                    />
                  </div>
                  <div className="mt-3">
                    <h4 className="subject_head">Subject Area</h4>
                    <div className="row">
                      {subject.name === "All"
                        ? subBranches.map((list) => {
                            return list?.subBranches?.map((branch) => {
                              return (
                                <div className="col-6 course-branch">
                                  <input
                                    type="checkbox"
                                    className="subject_check"
                                  />{" "}
                                  <span className="">{branch}</span>
                                </div>
                              );
                            });
                          })
                        : subBranches
                            .find((list) => {
                              return list.subjectId === subject.id;
                            })
                            .subBranches?.map((sub) => {
                              return (
                                <div className="col-6 course-branch">
                                  <input
                                    type="checkbox"
                                    className="subject_check"
                                    onClick={(e) =>
                                      handleSelectedSubject(e, sub)
                                    }
                                  />{" "}
                                  <span className="">{sub}</span>
                                </div>
                              );
                            })}
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="mt-3">
                    <h4 className="subject_head">Study Levels</h4>
                    <div className="row">
                      {studyLevels.map((list) => {
                        return (
                          <div className="col-6 course-branch">
                            <input type="checkbox" className="subject_check" />{" "}
                            <span className="">{list.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="mt-3">
                    <h4 className="subject_head">Study Destinations</h4>
                    <div className="row">
                      {studyDestinations.map((list) => {
                        return (
                          <div className="col-6 course-branch">
                            <input type="checkbox" className="subject_check" />{" "}
                            <span className="">{list.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </OffcanvasBody>
        <div className="filter-footer">
          <div>
            <button className="filter-btn" onClick={() => cancelFilter()}>
              Cancel
            </button>
          </div>
          <div>
            <button className="filter-btn">Apply filter</button>
          </div>
        </div>
      </Offcanvas>
      <Offcanvas
        isOpen={isSubject}
        toggle={() => setIsSubject(!isSubject)}
        direction="end"
        className="subject-canvas"
      >
        <OffcanvasHeader toggle={() => setIsSubject(!isSubject)}>
          Select subject area
        </OffcanvasHeader>
        <OffcanvasBody>
          <div>
            <SortedArrayComponent
              sortedArray={subjects}
              setSubject={setSubject}
              subjectToggle={subjectToggle}
            />
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
}

export default FindCourse;
